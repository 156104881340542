import React, { lazy } from 'react';
import './App.css';
import { useAuth } from "./AuthContext";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import SharedLayout from "./SharedLayout";

const Login = lazy(() => import('./login'));
const ErrorReponse = lazy(() => import('./error/ErrorReponse'));

const ErrorNetwork = lazy(() => import('./error/ErrorNetwork'));
const Action = lazy(() => import('./admin/action'));
const Application = lazy(() => import('./admin/application'));
const Groupe = lazy(() => import('./admin/groupe'));
const Owner = lazy(() => import('./admin/owner'));
const RouteApp = lazy(() => import('./admin/route'));
const User = lazy(() => import('./admin/user'));
const Order = lazy(() => import('./order'));
const Statics = lazy(() => import('./statics'));
const Recouvrement = lazy(() => import('./recouvrement'));
const Patrimoine = lazy(() => import('./patrimoine'));
const Arriere = lazy(() => import('./arriere'));
const Constatation = lazy(() => import('./constation'));
const ChangePassword = lazy(() => import('./admin/password_change'));
const FicheSynthese = lazy(() => import('./ficheSynthese'));
const Bien = lazy(() => import('./biens'));
const HelloUser = lazy(() => import('./helloUser'));



function App() {
  const { code } = useAuth();
  //localStorage.setItem('admin_Language', 'fr');

  return (
    <div>
      <BrowserRouter basename="">
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<SharedLayout />}>
                <Route index element={<HelloUser />} />
                <Route path="/action" element={<Action />} />
                <Route path="/application" element={<Application />} />
                <Route path="/groupe" element={<Groupe />} />
                <Route path="/owner" element={<Owner />} />
                <Route path="/route" element={<RouteApp />} />
                <Route path="/user" element={<User />} />
                <Route path="/order" element={<Order />} />
                <Route path="/statics" element={<Statics />} />
                <Route path="/patrimoine" element={<Patrimoine />} />
                <Route path="/arriere" element={<Arriere />} />
                <Route path="/recouvrement" element={<Recouvrement />} />
                <Route path="/constatation" element={<Constatation />} />
                <Route path="/ficheSynthese" element={<FicheSynthese />} />
                <Route path="/bien" element={<Bien />} />
                <Route path="/changePassword" element={<ChangePassword />} />
                <Route path="/Error" element={<ErrorReponse status={code} />} />
                <Route path="*" element={<ErrorReponse status={404} />} />
              </Route>
            </Route>

            <Route element={<SharedLayout />}>
              <Route path="/ErrorNetwork" element={<ErrorNetwork />} />
            </Route>
            <Route path="/Login" element={<Login />} />
          </Routes>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
