import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider} from "./AuthContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

const lang = localStorage.getItem('admin_Language');
if(!lang){
    localStorage.setItem('admin_Language' , 'fr');
}

root.render(
    <AuthProvider>
        <App />
    </AuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
