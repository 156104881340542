import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.headers.common['SameSite'] = 'Lax';
let baseURL = "http://localhost:8000";

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://back.opgi-boumerdes.dz';
} 

export const api = axios.create({
    withCredentials: true,
    headers: {
      'SameSite': 'None', 
      'Secure': true,     
    },
    baseURL: baseURL,
    credentials: 'include',
  });