import {  Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "./env";

const ProtectedRoute = ({ children }) => {
  const {   setCode, setUser, setBundle, setLanguage , setCompenentName } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    (
      
      async () => {
        try {
          const response = await api.get("/api/user");
          //const data = response.data;
          const status = response.status;
          if (status >= 200 && status < 300) {
            
            const userData = response.data;
            setUser(userData.data);
            localStorage.setItem("admin_Language", userData.data.language)
            setLanguage(userData.data.language);
            if (userData.data.language === "ar") {
              import(`./bundle/ar.json`)
                .then((bundle) => setBundle(bundle))
                .catch((error) => {
                  console.error("Error loading language bundle:", error);
                  setBundle(require("./bundle/ar.json"));
                });
            } else {
              import(`./bundle/fr.json`)
                .then((bundle) => setBundle(bundle))
                .catch((error) => {
                  console.error("Error loading language bundle:", error);
                  setBundle(require("./bundle/fr.json"));
                });
            }
            if (userData.data != null && (userData.data.isPasswordChange === 0 || userData.data.isPasswordChange === '0' )) {
              navigate("/changePassword");
            }
          }
          
        } catch (error) {
          if (error.hasOwnProperty("response") && error.response.status) {
            navigate("/Login");
          } else if (error.code === "ERR_NETWORK") {
            setCode(error.code);
            navigate("/ErrorNetwork");
          }
        }
        setCompenentName('List');
      }
    )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setCode, setUser, setBundle, navigate]);

  return children ? children : <Outlet />;
}
export default ProtectedRoute;