import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaBars } from "react-icons/fa";
import { api } from "./env";
import { useAuth } from "./AuthContext";
import React, { useState, Suspense, lazy } from "react";
const Loader = lazy(() => import("./compenents/loader/loader"));
const NavBar = lazy(() => import("./compenents/navBar/navBar"));
const SharedLayout = () => {
  const { user, language, showSlider, setIsAuthentified, setShowSilder } = useAuth();
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const logout = async () => {
    setShowLoader(true);
    await api("/api/logout");
    setIsAuthentified(false);
    navigate("/Login");
    setShowLoader(false);
  }


  return <Suspense fallback={<Loader show={showLoader} />}>
    <div className={((user && user.language === "ar") || language === "ar") ? "container-fluid rtl" : "container-fluid"}>

      {user && <nav className="navbar fixed-top  navbar-light bg-light dashbord-nav nav-z-index">
        <div className="container">
          <button
            className="btn navbar-brand"
            onClick={() => setShowSilder(!showSlider)}
          >
            <FaBars />  <span>{user && user.language === "ar" ? "فضاء OPGI" : "Espace OPGI"}</span>
          </button>
          <ul className="navbar-nav flex-row">
            <li className="nav-item me-3 me-lg-1 d-none d-md-block">
              {(user) && <button
                className="btn btn-language"
                disabled={showLoader}
                onClick={() => logout()}
              >
                <span className="m-1">{user.name}</span>
                <FaSignOutAlt />
              </button>}
            </li>


          </ul>

        </div>
      </nav>}
      {user && <NavBar></NavBar>}
      <section className="dashbord-section">
        <Suspense fallback={<Loader show={true} />}>
          <Outlet ></Outlet>
        </Suspense>
      </section>

    </div>
  </Suspense>
}
export default SharedLayout;